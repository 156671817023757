import {
  useRefinementList,
} from 'react-instantsearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@awesome.me/kit-875b9afcad/icons/sharp/regular';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useEffect, useState } from 'react';
import { Title } from '../../components/Title';
import { useSelector } from 'react-redux';

export default function AlgSearchHeader(props) {
  const experience_types = useSelector((state) => state.experience_types);

  return (
    <>
      <ActivityRefinement
        {...props}
        terms={experience_types}
        attribute={'activity'}
      />
    </>
  );
}

const ActivityRefinement = ({ terms, ...props }) => {
  const { items } = useRefinementList(props);

  return (
    <>
      <div className="flex items-center justify-between gap-2 mb-5">
        <Title>Search</Title>
        {/*<div*/}
        {/*  className="p-3 bg-black text-white flex items-center gap-2 text-sm">*/}
        {/*  <FontAwesomeIcon icon={Icons['faSliders']} className="text-xl" />*/}
        {/*  Filters*/}
        {/*</div>*/}
      </div>

      <div className="-gutter-x border-slate-200 border-b sticky top-0 bg-white">
        <Swiper
          spaceBetween={16}
          slidesPerView={4.5}
          className="gutter-x"
        >
          {/*{items.map((item) => {*/}
          {/*  // Map algolia facet items to drupal taxonomy terms*/}
          {/*  const term = terms.find(term => term.name === item.value);*/}

          {/*  return (*/}
          {/*    <SwiperSlide key={item.label}>*/}
          {/*      <ActivityRefinementItem item={item} term={term} {...props} />*/}
          {/*    </SwiperSlide>*/}
          {/*  )*/}
          {/*})}*/}
          {items.length > 0 && terms.map((term) => {
            // Map algolia facet items to drupal taxonomy terms
            const item = items.find(item => item.label === term.name);

            return (
              <SwiperSlide key={item.label}>
                <ActivityRefinementItem item={item} term={term} {...props} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

const ActivityRefinementItem = ({ item, term, ...props }) => {
  const { refine, sendEvent } = useRefinementList(props);

  return (
    <>
      <div
        className={`flex flex-col items-center justify-center gap-3 py-3 border-b-2 ${item.isRefined ? 'opacity-100 border-black' : 'text-black/60 border-transparent'}`}
        onClick={(event) => {
          refine(item.value);
          sendEvent('click', 'facet', 'price', item.label);
        }}
      >
        <FontAwesomeIcon icon={Icons[term.icon]} className="text-xl" />
        <div className="text-xs">{item.label}</div>
      </div>
    </>
  );
};
