import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMemoCircleCheck, faWifi } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import React from 'react';
import WelcomeMenu from '../../components/welcome/WelcomeMenu';
import { PageTitle } from '../../components/Title';

export default function WelcomePagesTemplate({icon, title, children}) {

  return (
    <>
      <WelcomeMenu/>
      <div className="bg-black text-white w-[80px] aspect-square flex items-center justify-center mb-5">
        <FontAwesomeIcon icon={icon} className="text-4xl" />
      </div>

      <PageTitle>{title}</PageTitle>

      {children}
    </>
  );
};