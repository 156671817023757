import { createSlice } from '@reduxjs/toolkit';


const taxActivitiesSlice = createSlice({
    name: 'taxonomy activities',
    initialState: [],
    reducers: {
        setActivitiesTaxonomy: (state, action) => action.payload,
    },
});

export const { setActivitiesTaxonomy } = taxActivitiesSlice.actions;

export default taxActivitiesSlice;