import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@awesome.me/kit-875b9afcad/icons/sharp/regular';

const BackButton = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <button onClick={handleBackClick} className="py-6 pe-6 text-sm flex gap-3 items-center">
      <FontAwesomeIcon icon={faArrowLeft}/> Back
    </button>
  );
};

export default BackButton;
