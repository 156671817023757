import { createSlice } from '@reduxjs/toolkit';


const rentalSlice = createSlice({
    name: 'rental',
    initialState: [],
    reducers: {
        setRentalInfo: (state, action) => action.payload,
    },
});

export const { setRentalInfo } = rentalSlice.actions;

export default rentalSlice;