import { Hits, InstantSearch, SearchBox } from 'react-instantsearch';
import algoliasearch from 'algoliasearch';

const ExperiencesDataProvider = ({ children }) => {
  const algoliaConfig = {
    indexName: 'experiences',
    searchClient: algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID,
      process.env.REACT_APP_ALGOLIA_API_KEY,
    ),
  };

  return (
    <InstantSearch
      indexName={algoliaConfig.indexName}
      searchClient={algoliaConfig.searchClient}
    >
      {children}
    </InstantSearch>
  );
};

export default ExperiencesDataProvider;