import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, NavLink } from 'react-router-dom';
import {
  faHome,
  faList,
  faMountainCity,
  faCommentsQuestion,
  faBook,
} from '@fortawesome/pro-regular-svg-icons';

const PrimaryNav = () => {

  return (
    <nav className="flex justify-center gap-8">
      <MenuItem icon={faHome} path="/" text="Home" />
      <MenuItem icon={faBook} path="/welcome" text="Welcome Book" />
      <MenuItem icon={faMountainCity} path="/experiences" text="Experiences" />
      <MenuItem icon={faCommentsQuestion} path="/faqs" text="FAQs" />
    </nav>
  );
};

export default PrimaryNav;

const MenuItem = ({ icon, text, path }) => {
  return (
    <NavLink
      to={path}
      className={({isActive}) => `flex flex-col justify-center items-center gap-2 text-black text-center py-3 border-t-2 ${isActive ? 'border-black' : 'opacity-50 border-transparent'}`}
    >
      <FontAwesomeIcon icon={icon} className="text-md" />
      <span className="text-2xs">{text}</span>
    </NavLink>
  );
};