import React from 'react';

export function Badge({children, ...props}) {


  return (
    <span className={`bg-black text-white px-3 py-2 text-xs ${props.className}`}>
      {children}
    </span>
  )
}