import { createSlice } from '@reduxjs/toolkit';


const experienceSlice = createSlice({
    name: 'experiences',
    initialState: [],
    reducers: {
        setExperiences: (state, action) => action.payload,
    },
});

export const { setExperiences } = experienceSlice.actions;

export default experienceSlice;