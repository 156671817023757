import {
    Configure,
    useHits,
} from 'react-instantsearch';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import ExperienceCardLarge from '../../experience/ExperienceCardLarge';
import ExperiencesDataProvider from '../../provider/ExperiencesDataProvider';

export default function AlgRecommendedExperiences({ heading, facetFilters = [], filters = '' }) {
    const experiences = useSelector((state) => state.experiences);

    return (
        <div className="py-5 mb-5">
            <h4 className="mb-3">{heading}</h4>
            <ExperiencesDataProvider>
                <Configure
                    filters={filters}
                    facetFilters={facetFilters}
                />
                <RecommendedSwiper experiences={experiences} />
            </ExperiencesDataProvider>
        </div>
    );
}

function RecommendedSwiper(props) {
    const { experiences } = props;
    const { results, sendEvent } = useHits(props);
    const [filteredResults, setFilteredResults] = useState([]);

    useEffect(() => {
        // Map algolia results to drupal experiences
        const idValues = results?.hits.map(idObj => idObj.nid.toString());
        const filteredLocations = experiences.filter(item => idValues.includes(item.id));
        setFilteredResults(filteredLocations);
    }, [results]);

    return (
        <>
            <div className="-gutter-x">
                <Swiper
                    spaceBetween={20}
                    slidesPerView={1.4}
                    className="gutter-x"
                >
                    {filteredResults.map((item) => (
                        <SwiperSlide key={item.id}>
                            <Link
                                to={`/experiences/${item.id}`}
                                className="text-inherit"
                                onClick={() => window.scrollTo(0, 0)}
                            >
                                <ExperienceCardLarge item={item} key={item.id} />
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    );
}