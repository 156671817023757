import CTACard from '../components/CTACard';
import {
  faClock,
  faCommentsQuestion,
  faHouse, faMountainCity,
} from '@fortawesome/pro-light-svg-icons';
import AlgRecommendedExperiences
  from '../algolia/widgets/RecommendedExperiences';
import React from 'react';
import { PageTitle } from '../components/Title';
import { Link } from 'react-router-dom';
import AlgSeasonalExperiences from '../algolia/widgets/SeasonalExperiences';
import AlgSitesExperiences from '../algolia/widgets/SitesExperiences';

const HomePage = () => {

  return (
    <>

      <PageTitle>Well howdy, stranger!</PageTitle>

      <div className="">
        <div className="text-lg font-semibold">Welcome to Buddy Barn</div>
        <p className="mb-9">Discover modern comforts, stunning views, and a
          serene atmosphere. Explore local attractions and activities to make
          your stay unforgettable. Enjoy your perfect getaway!</p>
      </div>

      <div className="grid grid-cols-2 gap-5 mb-12">
        <CTACard
          path="/welcome"
          title="Your Rental" icon={faHouse}
          text="Everything about your rental" className="bg-black text-white" />
        <CTACard
          path="/experiences"
          title="Things to do" icon={faMountainCity}
          text="Discover new experiences" className="bg-black text-white" />
        <CTACard
          path="/faqs"
          title="FAQs" icon={faCommentsQuestion}
          text="Find answers to common questions"
          className="bg-black text-white" />
        <CTACard
          path="/welcome/check-in-out"
          title="Checkout" icon={faClock}
          text="Complete your journey smoothly"
          className="bg-black text-white" />
      </div>

      <div className="flex flex-row gap-5 justify-between items-baseline">
        <h2>Experiences</h2>
        <Link to={'experiences'} className="text-sm">See All</Link>
      </div>

      <AlgRecommendedExperiences
        heading="Popular Experiences"
      />

      <AlgSeasonalExperiences/>
      <AlgSitesExperiences site="Waterfalls"/>
      <AlgSitesExperiences site="Views"/>
    </>
  );
};

export default HomePage;
