import { faHouse, faKey } from '@fortawesome/pro-light-svg-icons';
import {
  faFileSignature,
  faMemoCircleCheck,
  faWifi,
} from '@fortawesome/pro-regular-svg-icons';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function WelcomeMenu() {

  return (
    <>
      <div className="-gutter-x border-b sticky top-0 bg-white mb-5">
        <Swiper
          slidesPerView={4.5}
          spaceBetween={5}
        >
          <SwiperSlide>
            <WelcomeMenuItem icon={faHouse} text="House" path="/welcome" />
          </SwiperSlide>
          <SwiperSlide>
            <WelcomeMenuItem icon={faWifi} text="Wifi" path="/welcome/wifi" />
          </SwiperSlide>
          <SwiperSlide>
            <WelcomeMenuItem icon={faMemoCircleCheck} text="House Rules" path="/welcome/rules" />
          </SwiperSlide>
          <SwiperSlide>
            <WelcomeMenuItem icon={faKey} text="Check-in/out" path="/welcome/check-in-out" />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}

const WelcomeMenuItem = ({ icon, text, path }) => {
  return (
    <NavLink
        to={path}
        className={({isActive}) => `w-full flex flex-col justify-center items-center gap-2 text-black text-center py-3 px-1 border-b-2 ${isActive ? 'border-black' : 'opacity-50 border-transparent'}`}
        end >
      <FontAwesomeIcon icon={icon} className="text-lg" />
      <span className="text-2xs">{text}</span>
    </NavLink>
  );
}