import React from 'react';

export function Tag({children, ...props}) {


  return (
    <span className={`bg-white text-black border border-black px-3 py-2 text-xs ${props.className}`}>
      {children}
    </span>
  )
}