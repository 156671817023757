import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  GoogleMap,
  Marker,
  LoadScript, useJsApiLoader,
} from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '200px'
};

const ExperienceMap = ({ item }) => {
  const { geofield } = item;
  const [lat, lng] = geofield.split(',').map(coord => parseFloat(coord.trim()));


  const marker1 = { lat: 35.048198, lng: -83.401043 };
  const marker2 = { lat: lat, lng: lng };
  console.log("PARSED GEOFIELD: ", marker2);

  ////////////

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(marker1);
    bounds.extend(marker2);
    map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])


  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={mapOptions}
    >
      <Marker position={marker1} icon="http://maps.google.com/mapfiles/ms/icons/green-dot.png"/>
      <Marker position={marker2} icon="http://maps.google.com/mapfiles/ms/icons/red-dot.png"/>
      <></>
    </GoogleMap>
  ) : <></>
};

export default React.memo(ExperienceMap);
