import {
  useHits,
} from 'react-instantsearch';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@awesome.me/kit-875b9afcad/icons/sharp/regular';
import * as SolidIcons from '@awesome.me/kit-875b9afcad/icons/sharp/solid';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ExperienceCardLarge
  from '../../experience/ExperienceCardLarge';

export default function AlgSearchResults(props) {
  const experiences = useSelector((state) => state.experiences);

  return (
    <>
      <SearchResults experiences={experiences} {...props} />
    </>
  );
}

function SearchResults(props) {
  const { experiences } = props;
  const { results, sendEvent } = useHits(props);
  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    // Map algolia results to drupal experiences
    const idValues = results?.hits.map(idObj => idObj.nid.toString());
    const filteredExperiences = experiences.filter(item => idValues.includes(item.id));
    setFilteredResults(filteredExperiences);
  }, [results]);


  return (
    <>
      <div className="py-5">
        {filteredResults.map((item) => (
          <div
            key={item.id}
            onClick={() => sendEvent('click', item, 'Hit Clicked')}
            onAuxClick={() => sendEvent('click', item, 'Hit Clicked')}
          >
            <SearchResult {...item} />
          </div>
        ))}
      </div>
    </>
  );
}


// TODO: Use ExperienceCardLarge component
function SearchResult(item) {
  const img_url = item.gallery_images.length > 0 ? item.gallery_images[0] : null;

  if (!img_url) {
    return (
      <div className="bg-red-50 p-3 mb-5">Broken Image; {item.id}</div>
    );
  }

  return (
    <Link className="block mb-5 text-inherit" to={`/experiences/${item.id}`}>
      <ExperienceCardLarge item={item}/>
    </Link>
  );
}