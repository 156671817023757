import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import React from 'react';
import { PageTitle } from '../components/Title';
import {
  faArrowRightLong
} from '@awesome.me/kit-875b9afcad/icons/sharp/regular';
import AlgRecommendedExperiences
  from '../algolia/widgets/RecommendedExperiences';
import AlgSeasonalExperiences from '../algolia/widgets/SeasonalExperiences';

const Experiences = () => {

  return (
    <>
      <PageTitle><div>Things</div> To Do</PageTitle>

      <p className="">Make your trip even more memorable by experiencing some local favorites.</p>

      <Link to="/experiences/search"
         className="block bg-black text-white p-5 pe-10 mb-12 -gutter-e"
      >
        <div className="flex items-center justify-between gap-5">
          <div>Search</div>
          <FontAwesomeIcon icon={faArrowRightLong} />
        </div>
      </Link>

      <AlgRecommendedExperiences
          heading="All Experiences"
      />

      <AlgSeasonalExperiences/>
    </>
  );
};

export default Experiences;