import React, { useEffect } from 'react';
import PrimaryNav from './PrimaryNav';
import { useDispatch } from 'react-redux';
import { saturateExperiencesSlice,
    saturateTaxActivitiesSlice } from '../api/experiences';
import { saturateFAQSlice, saturateRentalInfoSlice } from '../api/rental';

const Layout = ({ children }) => {

  // Saturate the redux store with data
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(saturateExperiencesSlice());
    dispatch(saturateTaxActivitiesSlice());
    dispatch(saturateRentalInfoSlice());
    dispatch(saturateFAQSlice());
  }, [dispatch]);

  return (
    <div className="gutter-x flex flex-col h-svh">
      {/*<header className="bg-white border-y -gutter-x fixed bottom-0 left-0 right-0 z-50">*/}
        {/*<PrimaryNav/>*/}
      {/*</header>*/}
      <main className="py-6">
        {children}
      </main>
      <footer className="bg-white border-y -gutter-x left-0 right-0 z-50 sticky bottom-0 mt-auto">
          <PrimaryNav/>
      </footer>
    </div>
  );
};

export default Layout;
