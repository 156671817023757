import AlgSearchHeader from '../../algolia/refinements/AlgSearchHeader';
import AlgSearchResults from '../../algolia/results/AlgSearchResults';
import ExperiencesDataProvider from '../../provider/ExperiencesDataProvider';

export default function Search() {

  return (
    <>
      <ExperiencesDataProvider>
        <AlgSearchHeader/>
        <AlgSearchResults/>
      </ExperiencesDataProvider>
    </>
  );
};