import AlgRecommendedExperiences from './RecommendedExperiences';
import React from 'react';

export default function AlgSeasonalExperiences() {

  const getSeason = () => {
    const month = new Date().getMonth() + 1; // getMonth() returns 0-11, so we add 1

    if (month >= 3 && month <= 5) {
      return "Spring";
    } else if (month >= 6 && month <= 8) {
      return "Summer";
    } else if (month >= 9 && month <= 11) {
      return "Fall";
    } else {
      return "Winter";
    }
  };

  return (
    <AlgRecommendedExperiences
      heading={`${getSeason()} Experiences`}
      facetFilters={[`season:${getSeason()}`]}
      // filters='(activity:"Hiking" OR activity:"Skiing")'
      filters='NOT activity:"Grocery"'
    />
  )

}