import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export default function CTACard({ title, icon, text, path, ...props }) {

  return (
    <Link to={path} className={`flex flex-col aspect-square p-5 ${props.className}`}>
      <div className="">
        <FontAwesomeIcon icon={icon} className="text-3xl"/>
      </div>
      <div className="mt-auto">
        <div className="font-semibold text-lg">
          {title}
        </div>
        <div className="text-xs opacity-70">
          {text}
        </div>
      </div>
    </Link>
  );
}
