import { setExperiences } from '../redux/experienceSlice';
import { setActivitiesTaxonomy } from '../redux/taxonomyActivitiesSlice';

// TODO: Change this to be parameters, instead of query string


export const experiences = async (params = []) => {

  const queryString = createQueryParams(params);
  const endpoint = `${process.env.REACT_APP_CDN_URL}/api/v1/experience?_format=json${queryString ? `&${queryString}` : ''}`;

  console.log('api/experiences:', endpoint);
  return fetch(endpoint)
    .then((response) => response.json())
    .then((data) => {
      console.log('api/experiences:', data);
      return data;
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};

const createQueryParams = (params) => {
    return params.map(param => {
        const key = Object.keys(param)[0];
        const value = param[key];
        return `${key}=${value}`;
    }).join('&');
};

export const saturateExperiencesSlice = () => async (dispatch) => {
  const endpoint = `${process.env.REACT_APP_CDN_URL}/api/v1/experience?_format=json&api-key=${process.env.REACT_APP_DRUPAL_API_KEY}`;

  try {
    const response = await fetch(endpoint);
    const data = await response.json();
    console.log('api/experiences: Saturating store', data);
    dispatch(setExperiences(data));
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const saturateTaxActivitiesSlice = () => async (dispatch) => {
  const endpoint = `${process.env.REACT_APP_CDN_URL}/api/v1/taxonomy/list/experience_type?_format=json&api-key=${process.env.REACT_APP_DRUPAL_API_KEY}`;

  try {
    const response = await fetch(endpoint);
    const data = await response.json();
    console.log('api/experiences: Saturating store', data);
    dispatch(setActivitiesTaxonomy(data));
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
