import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { PageTitle } from '../components/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
} from '@awesome.me/kit-875b9afcad/icons/sharp/regular';

export default function FAQs(props) {
  const faqs = useSelector((state) => state.faqs);
  const [activeIndex, setActiveIndex] = useState(null)

  return (
    <>
      <PageTitle>Frequently Asked Questions</PageTitle>

      {/*{faqs.map((faq) => (*/}
      {/*  <>*/}
      {/*    <h1>{faq.question}</h1>*/}
      {/*    <div*/}
      {/*      className="text-sm opacity-80 mb-5"*/}
      {/*      dangerouslySetInnerHTML={{ __html: faq.answer }}*/}
      {/*    ></div>*/}
      {/*  </>*/}
      {/*))}*/}

      {faqs.map((faq) => (
        <div className="" key={faq.id}>
          <AccordionItem heading={faq.question} index={faq.id} active={faq.id === activeIndex} setIndex={setActiveIndex}/>
          <Accordion body={faq.answer} index={faq.id} active={faq.id === activeIndex} setIndex={setActiveIndex}/>
        </div>
      ))}
    </>
  );
}

const AccordionItem = ({heading, index, active, setIndex}) => {

  const handleItemClick = (index) => {
    setIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="text-2xl font-semibold py-5 border-y flex justify-between gap-5" onClick={() => handleItemClick(index)}>
      <div className="">{heading}</div>
      <FontAwesomeIcon icon={active ? faChevronUp : faChevronDown} className="text-lg"/>
    </div>
  )
}

const Accordion = ({body, active}) => {


  return (
    <div className={`py-5 ${active ? '' : 'hidden'}`}>
      <div
        className="text-sm opacity-80 mb-5"
        dangerouslySetInnerHTML={{ __html: body }}
      ></div>
    </div>
  )
}