import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWifi } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import React from 'react';
import WelcomePagesTemplate from './WelcomePagesTemplate';

export default function Wifi() {

  return (
    <>
      <WelcomePagesTemplate icon={faWifi} title="Wifi">

        <div className="font-semibold text-sm mb-2">Network:</div>
        <div className="bg-black text-white mb-5 p-5">BuddyBarn</div>

        <div className="font-semibold text-sm mb-2">Password:</div>
        <div className="bg-black text-white mb-5 p-5">constructed1916</div>

        <div className="">
          <p className="text-sm mb-9 opacity-60">It's important to note that we do not have cellular service at Buddy Barn. Without Wifi, you will not have access to the internet. See <Link to="welcome/cellular">cellular</Link> for more information.</p>
        </div>
      </WelcomePagesTemplate>
    </>
  );
};