import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMemoCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import React from 'react';
import {
  faBanSmoking,
  faFaceSmileHearts,
  faPeopleRoof,
} from '@fortawesome/pro-light-svg-icons';
import WelcomePagesTemplate from './WelcomePagesTemplate';

export default function Rules() {

  return (
    <>
      <WelcomePagesTemplate icon={faMemoCircleCheck} title="House Rules">
        <div className="">
          <RuleItem icon={faBanSmoking} text="No Smoking" >
            <p>Smoking is not allowed in the
              house but do, please enjoy smoking outside on the patio! We only ask that you please dispose of cigarette butts in the
              trash.</p>
          </RuleItem>

          <RuleItem icon={faPeopleRoof} text="Occupancy" >
            <p>The maximum number of guests is 6. This is for emergency purposes.</p>
          </RuleItem>

          <RuleItem icon={faFaceSmileHearts} text="Enjoy your stay" >
            <p>Lastly, we ask that you enjoy yourself! Make yourself at home and please let us know if you find it challenging to have the best time.</p>
          </RuleItem>
        </div>
      </WelcomePagesTemplate>
    </>
  );
};

const RuleItem = ({ icon, text, children }) => {
  return (
    <div className="flex items-start gap-4">
      <div className="bg-black text-white w- aspect-square flex items-center justify-center p-3">
        <FontAwesomeIcon icon={icon} className="text-2xl" />
      </div>
      <div>
        <div className="font-roboto-condensed text-2xl font-semibold mb-2">{text}</div>
        <div className="text-sm mb-9 opacity-60">
          {children}
        </div>
      </div>
    </div>
  );
};