import { configureStore } from '@reduxjs/toolkit';
import experienceSlice from './experienceSlice';
import taxActivitiesSlice from './taxonomyActivitiesSlice';
import rentalSlice from './rentalSlice';
import faqSlice from './faqSlice';


const store = configureStore({
  reducer: {
    experiences: experienceSlice.reducer,
    experience_types: taxActivitiesSlice.reducer,
    rental: rentalSlice.reducer,
    faqs: faqSlice.reducer
  },
});

export default store;
