import AlgRecommendedExperiences from './RecommendedExperiences';
import React from 'react';

export default function AlgSitesExperiences({site}) {

  return (
    <AlgRecommendedExperiences
      heading={`${site} Experiences`}
      facetFilters={[`sites:${site}`]}
    />
  )

}