import React from 'react';

export function PageTitle({ children, ...props }) {

  return (
    <>
      <div className={`my-9 ${props.className}`}>
        <Title>
          {children}
        </Title>
      </div>
    </>
  );
}

export function Title({ children }) {

  return (
    <>
      <h1 className="font-semibold text-6xl mb-0">{children}</h1>
    </>
  );
}