import { createSlice } from '@reduxjs/toolkit';


const faqSlice = createSlice({
    name: 'faqs',
    initialState: [],
    reducers: {
        setFAQs: (state, action) => action.payload,
    },
});

export const { setFAQs } = faqSlice.actions;

export default faqSlice;