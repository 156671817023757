import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@awesome.me/kit-875b9afcad/icons/sharp/regular';
import { faLocationArrow, faThumbsUp } from '@awesome.me/kit-875b9afcad/icons/sharp/solid';
import { Badge } from '../components/Badge';

export default function ExperienceCardLarge({ item }) {
  const { title, gallery_images, category, distance } = item;
  const image_url = gallery_images?.length > 0 ? gallery_images[0] : '';

  return (
    <>
      <div className="relative">
        {image_url &&
          <img
            src={process.env.REACT_APP_CDN_URL + '/' + image_url}
            alt={title}
            className="w-full h-full aspect-3/2 object-cover object-center overflow-hidden mb-2"
          />
        }
        {category &&
          <Badge className="absolute top-3 left-3">
            {category}
          </Badge>
        }
      </div>
      <div className="flex items-start justify-between gap-5">
        <div className="font-medium">{title}</div>
        <FontAwesomeIcon icon={faArrowRight} className="text-sm"/>
      </div>

      <div className="flex items-center gap-4 text-2xs font-medium opacity-30">
        {/*<div className="flex items-center gap-2">*/}
        {/*  <FontAwesomeIcon icon={faThumbsUp} className=""/> 22 Guests*/}
        {/*</div>*/}
        <div
          className="flex items-center gap-2">
          <FontAwesomeIcon icon={faLocationArrow} /> {distance} miles
        </div>
      </div>
    </>
  );
}