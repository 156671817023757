import { setRentalInfo } from '../redux/rentalSlice';
import { setFAQs } from '../redux/faqSlice';

export const saturateRentalInfoSlice = () => async (dispatch) => {
  const endpoint = `${process.env.REACT_APP_CDN_URL}/api/v1/rental?_format=json&api-key=${process.env.REACT_APP_DRUPAL_API_KEY}`;

  try {
    const response = await fetch(endpoint);
    const data = await response.json();
    console.log('api/rental: Saturating store', data);
    dispatch(setRentalInfo(data));
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const saturateFAQSlice = () => async (dispatch) => {
  const endpoint = `${process.env.REACT_APP_CDN_URL}/api/v1/rental/faqs?_format=json&api-key=${process.env.REACT_APP_DRUPAL_API_KEY}`;

  try {
    const response = await fetch(endpoint);
    const data = await response.json();
    console.log('api/rental/faq: Saturating store', data);
    dispatch(setFAQs(data));
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};