import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationArrow,
  faThumbsUp as faLiked,
} from '@fortawesome/pro-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  faBookmark,
  faThumbsUp as faLike,
} from '@fortawesome/pro-light-svg-icons';
import AlgRecommendedExperiences
  from '../algolia/widgets/RecommendedExperiences';
import { useSelector } from 'react-redux';
import ExperienceMap from './ExperienceMap';
import { Badge } from '../components/Badge';
import { Tag } from '../components/Tag';
import {
  faArrowLeft,
} from '@awesome.me/kit-875b9afcad/icons/sharp/regular';
import BackButton from '../components/NavBackButton';

export default function ExperienceNode(props) {
  const { id } = useParams();
  const [heroImg, setHeroImg] = useState();
  const experiences = useSelector((state) => state.experiences);

  const item = experiences.find(item => item.id === id);

  useEffect(() => {
    setHeroImg(item?.gallery_images[0] ?? '');
  }, [item]);

  if (!item) {
    return (<h1>LOADING</h1>);
  }

  const directionsURL = `https://www.google.com/maps/dir/?api=1&destination=${item.address}`;

  return (
    <>

      <div className="">
        <BackButton/>
        <div className="relative">
          <img
            src={process.env.REACT_APP_CDN_URL + '/' + heroImg}
            alt={item.title}
            className="w-full h-[400px] object-cover rounded-2xl overflow-hidden shadow-xl mb-3"
          />
          {item.category &&
            <Badge className="absolute top-5 left-5">
              {item.category}
            </Badge>
          }
          <div className="flex items-center gap-3 absolute bottom-5 right-5">
            {item.address &&
              <a
                href={directionsURL}
                target="_blank"
                className="bg-slate-50 w-[60px] aspect-square flex items-center justify-center rounded-full">
                <FontAwesomeIcon
                  icon={faLocationArrow}
                  className="text-cyan-700 text-xl" />
              </a>
            }
            {/*<div*/}
            {/*  className="bg-slate-50 w-[60px] aspect-square flex items-center justify-center rounded-full">*/}
            {/*  <FontAwesomeIcon*/}
            {/*    icon={faLike}*/}
            {/*    className="text-slate-600 text-opacity-70 text-xl" />*/}
            {/*</div>*/}
            {/*<div*/}
            {/*  className="bg-slate-50 w-[60px] aspect-square flex items-center justify-center rounded-full">*/}
            {/*  <FontAwesomeIcon*/}
            {/*    icon={faBookmark}*/}
            {/*    className="text-slate-600 text-opacity-70 text-xl" />*/}
            {/*</div>*/}
          </div>
        </div>
      </div>

      <div className="mb-4">
        <h1 className="mb-2">{item.title}</h1>
        <div className="flex items-center gap-4 mb-2">
          <div
            className="shrink-0 flex items-center text-xs font-semibold text-slate-700 gap-1.5">
            <FontAwesomeIcon icon={faLocationArrow} className="text-cyan-700" />
            {item.distance} miles
          </div>
          {/*<div*/}
          {/*  className="shrink-0 flex items-center text-xs font-semibold text-slate-700 gap-1.5">*/}
          {/*  <FontAwesomeIcon icon={faLiked} className="text-cyan-700" />*/}
          {/*  22 Guests*/}
          {/*</div>*/}
        </div>
      </div>

      <ExperienceGalleryThumbs
        images={item.gallery_images}
        title={item.title}
        setHeroImg={setHeroImg}
      />

      <ExperienceTags tags={item.tags} />

      <div
        className="text-sm opacity-80 mb-5"
        dangerouslySetInnerHTML={{ __html: item.summary }}
      ></div>
      {/*<h5>Details</h5>*/}
      {/*<div dangerouslySetInnerHTML={{ __html: item.body }}></div>*/}

      <div class="">
        {item &&
          <ExperienceMapWidget item={item} />
        }
      </div>

      <AlgRecommendedExperiences
        heading="Similar Experiences"
        facetFilters={['activity:' + item.category]}
        filters={`nid != ${item.id}`}
      />
    </>
  );
};

const ExperienceGalleryThumbs = ({ images, title, setHeroImg }) => {
  return (
    <div className="-gutter-x mb-5">
      <Swiper
        spaceBetween={8}
        slidesPerView={4.3}
        className="gutter-x"
      >
        {images.map((url, index) => {
            return (
              <SwiperSlide key={index} onClick={() => setHeroImg(url)}>
                <img
                  key={index}
                  src={process.env.REACT_APP_CDN_URL + '/' + url}
                  alt={title}
                  className="aspect-square object-cover rounded-2xl overflow-hidden shadow-xl"
                />
              </SwiperSlide>
            );
          },
        )}
      </Swiper>
    </div>
  );
};

const ExperienceTags = ({ tags }) => {
  return (
    <div className="flex flex-wrap gap-2 mb-5">
      {tags.map((tag, index) => {
          return (
            <Tag key={index}>
              {tag}
            </Tag>
          );
        },
      )}
    </div>
  );
};

const ExperienceMapWidget = ({ item }) => {

  if (!item.geofield) {
    return null;
  }

  return (
    <div className="mb-5">
      <div className="w-full h-[200px] overflow-hidden">
        {/*<ExperienceMapWithTether item={item} />*/}
        <ExperienceMap item={item} />
      </div>
      <div
        className="flex items-center text-xs font-semibold text-cyan-700 gap-2 my-2">
        <FontAwesomeIcon icon={faLocationArrow} />
        {item.distance} miles (driving)
      </div>
    </div>
  );
};