import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMemoCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import React from 'react';
import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faBanSmoking,
  faFaceSmileHearts, faKey,
  faPeopleRoof,
} from '@fortawesome/pro-light-svg-icons';
import WelcomePagesTemplate from './WelcomePagesTemplate';

export default function CheckInOut() {

  return (
    <>
      <WelcomePagesTemplate icon={faKey} title="Check-in / Check-out">
        <div className="">

          <ProcedureItem icon={faArrowRightToBracket} text="Check-in | 3:00 PM" >
            <ul className="list-disc ms-5 mb-5">
              <li>If you plan to arrive earlier, inform us in advance to arrange suitable accommodations.</li>
              <li>Code for the keyless entry will be sent via messages on or before the day of check-in.</li>
            </ul>
          </ProcedureItem>

          <ProcedureItem icon={faArrowRightFromBracket} text="Check-out | 11:00 AM" >
            <ul className="list-disc ms-5 mb-5">
              <li>If you plan to arrive earlier, inform us in advance to arrange suitable accommodations.</li>
              <li>Code for the keyless entry will be sent via messages on or before the day of check-in.</li>
            </ul>
          </ProcedureItem>

          <ProcedureItem icon={faFaceSmileHearts} text="Enjoy your stay" >
            <p>Lastly, we ask that you enjoy yourself! Make yourself at home and please let us know if you find it challenging to have the best time.</p>
          </ProcedureItem>

        </div>
      </WelcomePagesTemplate>
    </>
  );
};

const ProcedureItem = ({ icon, text, children }) => {
  return (
    <div className="flex items-start gap-4">
      <div className="bg-black text-white w- aspect-square flex items-center justify-center p-3">
        <FontAwesomeIcon icon={icon} className="text-2xl" />
      </div>
      <div>
        <div className="font-roboto-condensed text-2xl font-semibold mb-2">{text}</div>
        <div className="text-sm mb-9 text-black/60">
          {children}
        </div>
      </div>
    </div>
  );
};